import { Reservation } from '@/interfaces/Reservation';
import { currentUserStore } from '@/stores/currentUser';
import { toastController  } from '@ionic/vue';
import { checkmarkCircleOutline, warningOutline,closeCircleOutline, tabletPortrait } from 'ionicons/icons';


export default function tools() {    

    const toast = async (message:string, color:'success'|'warning'|'danger',duration=2500) =>{
        let _color = 'succcess' // por defecto
        switch(color){
            case 'success': _color = checkmarkCircleOutline; break;
            case 'warning': _color = warningOutline; break;
            case 'danger': _color = closeCircleOutline; break;
        }
        const _toast = await toastController.create({
            message: message,
            duration: duration,
            position: 'top',
            color: color,
            icon: _color,
          });
  
          await _toast.present();
    }

    const delay = async (milliseconds:number)=> {
        return new Promise(resolve => {
          setTimeout(resolve, milliseconds);
        });
  }

    
      //arreglar el formato de datos que viene del servidor y lo transforma en un json estructurado
      const arreglarDatos_Disponibilidad = (dispo) =>{
        const tmp =  []

        //sacamos las diferentes horas disponibles
        let horas = dispo.map((val)=> val['hora'])
        horas = [...new Set(horas)]; //sacamos valores unicos
        //buscamos para cada hora las distintas pistas disponibles
        horas.forEach(element => {
          const level2= {
            hora: element,
            pistas: dispo.filter(val=> val.hora==element && !val.reservada ).map(val=> {return {
                                                                                                name: val['pista'], 
                                                                                                id: val['pista_id'],
                                                                                                players: val['players_pista'],
                                                                                                playerList: val['playerList'],
                                                                                              }} )
          }
          tmp.push(level2)
        });

        return tmp
      }

      //extrae el numero de reservas del jugador logeado de un jugador de una reserva concreta
      const numReservasAuthUser = (reserva:Reservation) : number =>{
        return reserva.playerList.find( user => user.id === currentUserStore().user.id ).players
      }

      //extrae el numero de reservas de otros jugadores que no son el logeado de una reserva concreta
      const numReservasNoAuthUser = (reserva:Reservation) : number =>{
        const tmp = reserva.playerList.filter( user => user.id !== currentUserStore().user.id )
        if(tmp === undefined) return 0
        return tmp.reduce( (accum,val) => accum + val.players,0)
      }


    return {
        toast,arreglarDatos_Disponibilidad,delay,numReservasAuthUser,numReservasNoAuthUser
    }
  }