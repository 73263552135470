import axios from 'axios';
import {User} from '@/interfaces/User';
import {Transaction} from '@/interfaces/Transaction';
import tools from "@/composables/tools";
import { currentUserStore } from '@/stores/currentUser';
import moment from 'moment';

export abstract class UserServices{
 
    static async register(myUser:User) : Promise<User>{
        try {
            const response = await axios.post('register',myUser)
            return response.data
        } catch (error) {

            if(axios.isAxiosError(error)){
                myUser.errors = error.response.data.errors
                console.log(myUser.errors)
                return myUser
            }
        }
    }

    static async reloadReservations(){
        try {
            const response = await axios.get('reservasUser')
            currentUserStore().$state.reservations = response.data
        } catch (error) {
            tools().toast('No se pueden cargar las reservas','danger',3000);
        }
    }

    static async reloadTransactions(){
        try {
            const response = await axios.get('transactions')
            currentUserStore().$state.transactions = response.data.data
        } catch (error) {
            tools().toast('No se pudieron cargar las transacciones','danger',3000);
        }
    }

    static async getSaldo(): Promise<number>{
        try {
            const response = await axios.get('saldo')
            return response.data
        } catch (error) {
            console.log(error)
        }
    }

    
    static async getRappelCreditos(){
        try {
            const response = await axios.get('rappelCreditos')
            currentUserStore().$state.rappelCreditos = response.data
        } catch (error) {
             console.log(error)
        }
    }

}