import axios from 'axios';
import {Deporte} from '@/interfaces/Deporte';
import {Disponibilidad} from '@/interfaces/Disponibilidad';
import {Reservation} from '@/interfaces/Reservation';
import {currentReservationStore} from '@/stores/currentReservation';
import {UserServices} from '@/services/UserServices';
import {Config} from '@/interfaces/Config';
import tools from "@/composables/tools";
import moment from 'moment';


export abstract class ReservationServices{

    static async getDeportes(){
        try {
            const response = await axios.get('deportes')
            currentReservationStore().$state.deportes = response.data
        } catch (error) {
             console.log(error)
        }
    }

 
    static async getConfig() : Promise<Config>{
        try {
            const response = await axios.get('config')
            return response.data
        } catch (error) {
            console.log('No se pudo ejecutar getConfig')
             console.log(error)
        }
    }

    static async getPlayers(start_at:string,pista_id:number){
        try {
            const response = await axios.get('reservation/players/' + start_at + '/' + pista_id)
            currentReservationStore().$state.reservation.playerList = response.data
        } catch (error) {
            console.log('No se pudo obtener la lista de jugadores de la reserva que comienza el ' + start_at + ' asociada a la pista ' + pista_id)
            console.log(error)
        }       
    }

    
  
    static async getDisponibilidadByDay(fecha:string,myDeporte:Deporte) : Promise<[]>{
        try {
            const response = await axios.get('getByDay',{params:{fecha:fecha,deporte:myDeporte}})

            return response.data
        } catch (error) {
            console.log('No se pudo obtener la disponibilidad para el dia ' + fecha)
             console.log(error)
        }
    }
  
    static async reloadDisponibilidad(limit=2){ //append define si lo que queremos es añadir nuevos al final o hacer una recarga de cero
        let actual = moment().add(currentReservationStore().$state.disponibilidad.length,'d')
        let tmp:Disponibilidad = null

        for(let i=0;i<limit;i++)
        {
          tmp = {
                                            date: actual.format('YYYY-M-D'),                                            
                                            dayOfWeek: actual.format('dddd'),                                            
                                            dayOfMonth: parseInt(actual.format('D')),                                            
                                            month: actual.format('MMMM'),
                                            disponibilidad: null                                           
                                            }
          const dispo = await ReservationServices.getDisponibilidadByDay(actual.format('YYYY-M-D'),currentReservationStore().$state.reservation.deporte)
          //arreglamos un poco los datos
          tmp.disponibilidad = tools().arreglarDatos_Disponibilidad(dispo)
          currentReservationStore().$state.disponibilidad.push(tmp)
          actual = actual.add(1,'d')  
         }
    }

 
    static async calculeTotal(){
        try {
            await axios.post('reservation/calculeTotal',currentReservationStore().$state.reservation)
            .then( response => {
                currentReservationStore().$state.reservation.total = response.data
            }) 
        } catch (error) {
            tools().toast(error.response.data.message,'danger',5000);
        }
    }

    static async confirmReserva(){
        try {
            await axios.post('reservation',currentReservationStore().$state.reservation)
            .then( (response) => {
                UserServices.reloadReservations()
                tools().toast(response.data.message,'success',3000);
                return response
            }) 
        } catch (error) {
            UserServices.reloadReservations()
            return error
        }   
    }

    static async deleteReserva(reserva_id:number){
        try {
            await axios.delete('reservation/' + reserva_id)
            .then( (response) => {
                UserServices.reloadReservations()
                tools().toast(response.data.message,'success',10000);
                return response
            }) 
        } catch (error) {
            return error
        }   
    }

    //añade un nuevo jugador desde mis reservas a la reserva que se pasa por parametro
    static async addJugador(myReserva:Reservation){
        try {
            await axios.post('reservation/addJugador',myReserva)
            .then( (response) => {
                tools().toast(response.data.message,'success',3000);
                return response
            }) 
        } catch (error) {
            return error
        }   
    }
}